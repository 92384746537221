<template>
  <div class="Home" v-if="$store.getters.isLoggedIn">
    <organiz-header
        v-show="currentUser.authorities[0].authority === 'ROLE_ADMIN'
                || currentUser.authorities[0].authority === 'ROLE_PROFESSOR'
                || currentUser.authorities[0].authority === 'ROLE_PROCTOR'"/>
    <student-header  v-show="currentUser.authorities[0].authority === 'ROLE_USER'"/>
      <main>
        <section class="uk-section uk-section-small uk-padding-remove-top">
          <div class="uk-container">
            <div class="uk-text-center uk-margin">
              <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
                <li>
                  <router-link v-if="currentUser.authorities[0].authority === 'ROLE_PROCTOR'"
                               title="OrganizationProcExamList" tag="a" :to="{name: 'OrganizationProcExamList'}">
                    {{ $t("globViews.personalInfo.home") }}
                  </router-link>
                  <router-link v-else title="organization" tag="a" :to="{name: 'organization'}">
                    {{ $t("globViews.personalInfo.home") }}
                  </router-link>
                </li>
                <li>
                  <span>{{$t("globViews.settings.set")}}</span>
                </li>
              </ul>
              <!--<h1 class="uk-margin-small-top uk-margin-remove-bottom">Student register</h1>-->
            </div>
            <div class="uk-width-1-1">
              <div v-if="currentUser.passType === 0">
                <div class="uk-alert-warning" uk-alert>
                  <a class="uk-alert-close" uk-close></a>
                  <p>{{$t("globViews.settings.pChange")}} <a href="#modal-passwordchange"
                  uk-toggle>{{$t("globViews.settings.cMe")}}</a></p>
                </div>
              </div>


            </div>
            <div class="uk-grid-medium" uk-grid>

              <div class="uk-width-1-1 uk-width-1-4@m tm-aside-column">
                <app-my-navmenu
                ></app-my-navmenu>
              </div>
              <div class="uk-width-1-1 uk-width-expand@m">
                <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
                  <header class="uk-card-header"><h1 class="uk-h2">{{$t("navMenu.set")}}</h1></header>
                  <div class="uk-card-body">
                    <div v-if="error!==null" class="uk-alert-warning" uk-alert>
                      <p v-html="error" class="uk-text-center"></p>
                    </div>

                    <div v-if="message!==null"
                         :class="{ 'uk-alert-success': success, 'uk-alert-danger':success===false }" uk-alert>

                      <p v-html="message"></p>
                    </div>

                    <div class="uk-form-stacked">
                      <div class="uk-grid-medium uk-child-width-1-1" uk-grid>
                        <!--<fieldset class="uk-fieldset">-->
                          <!--<legend class="uk-h4">Email</legend>-->
                          <!--<div class="uk-grid-small uk-child-width-1-1" uk-grid>-->
                            <!--<div><label>-->
                              <!--<div class="uk-form-label">Current Email</div>-->
                              <!--<input class="uk-input uk-form-width-large" type="email"-->
                                     <!--:value="currentUser.email" disabled></label></div>-->
                            <!--<div><label>-->
                              <!--<div class="uk-form-label">New Email</div>-->
                              <!--<input class="uk-input uk-form-width-large" type="email"></label></div>-->
                            <!--<div>-->
                              <!--<button type="button" class="uk-button uk-button-primary">update email</button>-->
                            <!--</div>-->
                          <!--</div>-->
                        <!--</fieldset>-->
                        <fieldset class="uk-fieldset uk-margin-left uk-margin-right">
                          <div class="uk-grid-small uk-child-width-1-1" uk-grid>
                            <div>
                              <label>
                              <div class="uk-form-label">{{$t("globViews.settings.cPass")}}</div>
                              <input v-model="currentpassword" class="uk-input uk-form-width-large"
                                     type="password"/>
                              </label>
                            </div>
                            <div><label>
                              <div class="uk-form-label">{{$t("globViews.settings.newPass")}}</div>
                              <input v-model="newpassword" class="uk-input uk-form-width-large" type="password"/></label>
                            </div>
                            <div><label>
                              <div class="uk-form-label">{{$t("globViews.settings.conPass")}}</div>
                              <input v-model="confirmpassword" :class="{ 'uk-form-danger': validconfirmpass }"
                                     class="uk-input uk-form-width-large"
                                     type="password"/></label>
                            </div>
                            <div>
                              <button type="button" @click="changepass" class="uk-button uk-button-primary">{{$t("globViews.settings.upPass")}}</button>
                            </div>
                          </div>
                        </fieldset>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <organiz-footer/>
    </div>
</template>


<script>
  import UserService from '@/services/UserService'
  import {mapGetters} from 'vuex'
  import MyNav from '@/components/GlobViews/MyNavmenu'
  import _ from 'lodash'

  import OrganizHeader from '@/components/Organizcmp/OrganizHeader.vue'
  import OrganizFooter from '@/components/Organizcmp/OrganizFooter.vue'
  import StudentHeader from '@/components/Studentcmp/StudentHeaderNew.vue'

  export default {
    name: 'Home',
    components: {
      appMyNavmenu: MyNav,
      OrganizHeader,
      OrganizFooter,
      StudentHeader,
    },
    created() {
      if (this.currentUser.authorities[0].authority === 'ROLE_USER'
      ) {
        this.layout = "StudentLayout";
      } else {
        this.layout = "OrganizLayout";
      }
      //alert(this.layout)
    },

    data() {
      return {
        layout: 'OrganizLayout',
        currentpassword: null,
        newpassword: null,
        confirmpassword: null,
        error: null,
        validconfirmpass: false,
        message: null,
        success: false
      }
    },
    computed: {
      ...mapGetters(['isLoggedIn', 'currentUser'])
    },
    async mounted() {
    },
    methods: {
      async changepass() {
        this.message = null
        this.error = null
        if (this.isLoggedIn) {

          let valid = this.newpassword == this.confirmpassword;
          if (!valid) {
            this.error = 'new password not valid'
            this.scrollToTop()
            return;
          }
          try {

            const response = await UserService.passwordChange({
              oldpass: this.currentpassword,
              newpass: this.newpassword,
              changeType:'userpasschange'
            });

            this.success = response.data.success
            this.message = response.data.message

            this.currentpassword = null
            this.newpassword = null
            this.confirmpassword = null

            this.scrollToTop()
          } catch (error) {
            // console.log(error.response)
            this.scrollToTop()
            this.error = error.response.data.message;

            this.currentpassword = null
            this.newpassword = null
            this.confirmpassword = null
          }


        }
      },
      scrollToTop() {
        window.scrollTo(0, 0)
      }
    },
    watch: {
      confirmpassword: _.debounce(async function () {

        if (this.confirmpassword === this.newpassword) {

          this.validconfirmpass = false
        } else {
          this.validconfirmpass = true
        }
      }, 50)
    }
  }
</script>

<style scoped>
</style>
